import { FC } from "react";
import { ExchangeWhiteLogo } from "../../svg/IconCollection";
import ProfileDropdown from "./ProfileDropdown";
import { IHeaderInfo } from "../../core/viewModels/HeaderInfo";
import { Home } from "../../common/icons/svgIcons";
import { getClientHubDomainURL } from "../../store/actions/oneHubActions";
import { useDispatch } from "react-redux";
import { LinkType, sessionStorageConstants } from "../../helper/Constants";

interface IHeaderProps {
  headerInfo: IHeaderInfo;
  clientId: string;
  encodedEmail?: string;
}

const Header: FC<IHeaderProps> = ({ headerInfo, clientId, encodedEmail }) => {
  const dispatch = useDispatch();
  const uteInfo = sessionStorage.getItem(sessionStorageConstants.fromUte);
  const linkType = uteInfo
    ? LinkType.DocumentRequestLink
    : LinkType.CompanyDropOff;
  const redirectToOneHub = () => {
    dispatch(
      getClientHubDomainURL(
        clientId,
        uteInfo,
        linkType,
        (oneHubDomainURL: string) => {
          //remove ute info before redirecting to onehub
          sessionStorage.removeItem(sessionStorageConstants.fromUte);
          window.location.href = oneHubDomainURL;
        }
      )
    );
  };

  return (
    <header className="layout-header vertical-horizontal-center">
      <div className="header-logo-name-container">
        <ExchangeWhiteLogo />
        <div className="separator-line"></div>
        <span className="company-name">{headerInfo.companyName}</span>
      </div>

      <div className="header-right-container">
        {(encodedEmail || uteInfo) && (
          <span className="header-home-info" title="Home">
            <a onClick={() => redirectToOneHub()}>
              <Home />
              <span>Home</span>
            </a>
          </span>
        )}
        <ProfileDropdown headerInfo={headerInfo} />
      </div>
    </header>
  );
};

export default Header;


import { Action, Reducer } from "redux";
import { actionTypes } from "../types/userTypes";
import produce from "immer";
import { DispatchActions } from "../actions/userActions";


export interface IUserState {
    emailId: string
}

const initialUserState: IUserState = {
    emailId: null
};

export const reducer: Reducer<IUserState> = produce(
  (draftState: IUserState = initialUserState, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.SET_USER_EMAIL:
            draftState.emailId = action.emailId;
        break;
        default:
            return draftState || initialUserState;
    }
  }
);
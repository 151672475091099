import * as SendLinkStore from "./reducers/sendLinkReducer";
import * as ErrorStore from "./reducers/errorReducer";
import * as NotificationStore from "./reducers/notificationReducer";
import * as DropOffStore from "./reducers/dropOffReducer";
import * as TokenStore from "./reducers/tokenReducer";
import * as OneHubStore from "./reducers/oneHubReducer";
import * as UserStore from "./reducers/userReducer";

// The top-level state object
export interface ApplicationState {
    sendLink: SendLinkStore.ISendLinkState;
    errorState: ErrorStore.ErrorState;
    notification: NotificationStore.NotificationState,
    dropOffState: DropOffStore.IDropOffState,
    refreshTokenState: TokenStore.IRefreshToken,
    oneHubState:OneHubStore.IOneHubState,
    userState : UserStore.IUserState

}

export const reducers = {
    sendLink: SendLinkStore.reducer,
    errorState: ErrorStore.reducer,
    notification: NotificationStore.reducer,
    dropOffState: DropOffStore.reducer,
    refreshTokenState: TokenStore.reducer,
    oneHubState: OneHubStore.reducer,
    userState: UserStore.reducer
};

export interface AppThunkAction<TAction> {(
        dispatch: (action: TAction | AppThunkAction<TAction>) => void,
        getState: () => ApplicationState
    ): void;
}

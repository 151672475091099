import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { logger } from "../App";
import { AuthenticationMethod } from "../core/common/enums";
import { AppNotifier } from "../helper/AppNotifier";
import { LogEventConstants, SendLinkConstants } from "../helper/Constants";
import { validateRecipient } from "../store/actions/sendLinkActions";
import EmailPopup from "./EmailPopup";
import { isRequestLink } from "../helper/HelperFunctions";

export interface accessLinkParams {
    clientId: string,
    fromUte?: string
}

const SendLink: React.FC = (props: any) => {
    const pageTitle = "SendLink page";
    const history = useHistory();
    const { clientId }: accessLinkParams = useParams();
    const dispatch = useDispatch();
    const [validationMessage, setValidationMessage] = useState("");
    const [email, setEmail] = useState<string>("");
    const type = props.type;

    const handleError = (result: any) => {
        logger.trackEvent(
            logger.buildEvent(`${pageTitle}: Email verification failed`, {
                page: pageTitle,
                clientId: clientId,
                error: result ? JSON.stringify(result) : "",
            })
        );
        setEmail("");
        if (result.errorCode && result.errorCode === "ERROR_INVALID_LINK") {
            
            if(result.errorDescription.includes(SendLinkConstants.INVALID_EMAIL_ERROR_TEXT)){
                setValidationMessage(result.errorDescription);
            }else{
                setValidationMessage("");
                AppNotifier.Warning(result.errorDescription)
            }
        } else {
            history.push("/Error");
        }
    };

    const handleSuccess = (data: any) => {

        props.setPreviousLocationClientId(clientId);
        sessionStorage.setItem("previousClientId", clientId);
        history.push(`/${isRequestLink(type) ? "DocumentRequest" : "SendLinkRedirect"}/${data.redirectUrl}`);

        logger.trackEvent(
            logger.buildEvent(
                `${pageTitle}: Email verification succeeded and redirecting...`,
                {
                    page: pageTitle,
                    clientId: clientId,
                    reponseData: JSON.stringify(data),
                }
            )
        );
        switch (data.message.authenticationMethod) {
            case AuthenticationMethod.ACCESSCODE:
                props.setActiveMenu("AccessCode");
                props.setActiveMenuIndex(1);
                break;
            case AuthenticationMethod.QUESTIONANSWER:
                props.setActiveMenu("QnA");
                props.setActiveMenuIndex(1);
                break;
        }
    };

    useEffect(() => {
        logger.trackPageView(pageTitle);
        logger.trackEvent(
            logger.buildEvent(`${LogEventConstants.Common.Open} ${pageTitle}`, {
                page: pageTitle,
                clientId: clientId,
            })
        );
    }, []);

    const handleOnSubmit = (email: string) => {
        dispatch(validateRecipient(clientId, email, handleSuccess, handleError, type));
    };

    return (
        <EmailPopup 
            onSubmit={handleOnSubmit} 
            validationMessage={validationMessage}
            clientId={clientId} 
            email={email}
            setEmail={setEmail}
            type={type} />

    );
};
export default SendLink;

import React, { ReactNode } from "react";
import { CustomModal } from "./CustomModal";


interface ICustomModalProps {
	children: ReactNode;
	show: boolean;
	cancelButtonName?: string;
	confirmButtonName?: string;
	additionalActionButtonName?: string;
	title?: string;
	onSubmit: () => void;
	onHide: () => void;
	size?: any; // any is required since size type can not be given as string
	className?: string;
  showAlert?: boolean;
  alertMessage?: string;
}

export const MessageModal: React.FC<ICustomModalProps> = (props) => {

	return (
		<CustomModal
			className={props.className}
			show={props.show}
			onHide={props.onHide}
			isConfirmModal={true}
			confirmButtonName="OK"
			title={props.title}
			onSubmit={props.onSubmit}
		>
			{props.children}		
		</CustomModal>
	);
};
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { IDropOffRecipient } from "../core/viewModels/DropffModel";
import {
  AccessModalConstants,
  LogEventConstants,
  OTPPageConstants,
} from "../helper/Constants";
import { generateOTP, verifyOTP } from "../store/actions/dropOffActions";
import { IOTPValidation } from "./OTPValidation";
import { logger } from "../App";
import { CloseXButton, SecurityShieldIcon } from "../common/icons/svgIcons";
import OtpInputControl from "./common/OtpInputControl";
import { DropOffType } from "../core/common/enums";
import { ToasterMessages } from "../helper/ToasterMessages";

interface IAccessCodeModalProps {
      showState: boolean;
      dropOffType: DropOffType; 
      onHide: () => void;
      clientId: any;
      sender: () => IDropOffRecipient;
      onSendMessage: (dropOffType: DropOffType) => void;
}

export const AccessCodeModal: React.FC<IAccessCodeModalProps> = (props) => {
  const dispatch = useDispatch();
  const [guid, setGuid] = useState("");
  const [state, setState] = React.useState<IOTPValidation>({
    otpVal: "",
    otpInvalidMessage: "",
  });

  const continueState = state.otpVal.length === OTPPageConstants.OtpLength;
  const accessModalText = AccessModalConstants.CARD_SUBTITLE.split("+");
  const handleSubmit = () => {
    logger.trackEvent(
      logger.buildEvent(`${LogEventConstants.Dropoff.AccessCodeModal.Title}: OTP submitted`, {
        count: 1,
        page: LogEventConstants.Dropoff.AccessCodeModal.Title,
        clientId: props.clientId,
        inputOtp: state.otpVal,
        guid: guid,
      })
    );
    continueState &&
      dispatch(
        verifyOTP(
          state.otpVal,
          guid,
          props.dropOffType, 
          props.clientId,
          handleOTPVerification,
          handleError
        )
      );
  };

    useEffect(() => {
        logger.trackEvent(
            logger.buildEvent(`${LogEventConstants.Common.Open}  ${LogEventConstants.Dropoff.AccessCodeModal.Title}`, {
                count: 1,
                page: LogEventConstants.Dropoff.AccessCodeModal.Title,
                clientId: props.clientId,
                senderEmail: props.sender().emailAddress,
                senderInfo: JSON.stringify(props.sender())
            })
        );
        dispatch(generateOTP(props.dropOffType, props.clientId, props.sender(), handleSuccess));
    }, []);

  const requestCode = () => {
     dispatch(generateOTP(props.dropOffType, props.clientId, props.sender(), handleSuccess, true));
    logger.trackEvent(
      logger.buildEvent(`${LogEventConstants.Dropoff.AccessCodeModal.Title}: request code clicked`, {
        page: LogEventConstants.Dropoff.AccessCodeModal.Title,
        clientId: props.clientId,
        senderEmail: props.sender().emailAddress,
        senderInfo: JSON.stringify(props.sender())
      })
    );
  };

  const handleSuccess = (generatedGuid: string) => {
    setGuid(generatedGuid);
    logger.trackEvent(
      logger.buildEvent(`${LogEventConstants.Dropoff.AccessCodeModal.Title}: access code generated`, {
        page: LogEventConstants.Dropoff.AccessCodeModal.Title,
        clientId: props.clientId,
        generatedGuid: generatedGuid,
        senderEmail: props.sender().emailAddress,
        senderInfo: JSON.stringify(props.sender())
      })
    );
  };

  const handleOTPVerification = () => {
    logger.trackEvent(
      logger.buildEvent(`${LogEventConstants.Dropoff.AccessCodeModal.Title}: otp verification completed successfully`, {
        page: LogEventConstants.Dropoff.AccessCodeModal.Title,
        clientId: props.clientId,
        senderEmail: props.sender().emailAddress,
        senderInfo: JSON.stringify(props.sender())
      })
    );
    props.onHide();
      props.onSendMessage(props.dropOffType);
  };

  const handleError = (result: any) => {
    setState((state) => ({ ...state, otpVal: "" }));
    if (
      result.errorCode &&
      (result.errorCode === "ERROR_INVALID_OTP_DROPOFF" ||
        result.errorCode === "OTP_EXPIRED" ||
        result.errorCode === "ACCESS_CODE_NOT_EXISTS")
    ) {
      result.errorCode === "ERROR_INVALID_OTP_DROPOFF" && setState((state) => ({ ...state, otpInvalidMessage: AccessModalConstants.INCORRECT_OTP_WARNING }));
      result.errorCode === "ACCESS_CODE_NOT_EXISTS" && setState((state) => ({ ...state, otpInvalidMessage: AccessModalConstants.EXCEED_MAX_ATTEMPT_WARNING}));
      result.errorCode === "OTP_EXPIRED" && setState((state) => ({ ...state, otpInvalidMessage: ToasterMessages.ERROR.EXPIRE_OTP }));
      logger.trackEvent(
        logger.buildEvent(
          `${LogEventConstants.Dropoff.AccessCodeModal.Title}: otp verification failed for incorrect/expired otp`,
          {
            page: LogEventConstants.Dropoff.AccessCodeModal.Title,
            clientId: props.clientId,
            otpInput: state.otpVal,
            errorCode: result.errorCode,
            senderEmail: props.sender().emailAddress,
            senderInfo: JSON.stringify(props.sender())
          }
        )
      );
    }
  };

  const handleOTPChange = (otp: string) => {
    setState((state) => ({ ...state, otpVal: otp }));
  };

  return (
    <Modal
      className="access-code-modal-container"
      backdrop="static"
      keyboard={false}
      show={props.showState}
      onHide={() => {
        props.onHide();
      }}
    >
      <Modal.Body>
        <div className="access-code-modal-body-container">
          <div className="close-button" onClick={props.onHide}>
            <CloseXButton />
          </div>
          <SecurityShieldIcon />
          <h4 className="card-title">{AccessModalConstants.CARD_TITLE}</h4>
          <p className="card-sub-title">
            {accessModalText[0]}
            <b>{accessModalText[1]}</b>
            {accessModalText[2]}
            <b>{props.sender().emailAddress}</b>
          </p>
          <p className="otp-expiry-text">{AccessModalConstants.EXPIRY_MESSAGE}</p>

          <OtpInputControl
            otp={state.otpVal}
            setOtp={handleOTPChange}
            isInvalid={state.otpInvalidMessage !== ""}
            errorText={state.otpInvalidMessage}
          />
          <Button
            className="button-primary-blue-2"
            variant="primary"
            disabled={!continueState}
            onClick={handleSubmit}
          >
            {AccessModalConstants.AUTHENTICATE_BUTTON_TEXT}
          </Button>
          <span className="spam-text">{OTPPageConstants.SPAM_WARNING}</span>

          <Button variant="link" className="resend-btn" onClick={requestCode}>
            {AccessModalConstants.RESEND_BUTTON_TEXT}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

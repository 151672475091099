import React, { ReactNode } from "react";
import { Modal, ModalHeader } from "react-bootstrap";

interface ICustomModalProps {
    children: ReactNode;
    show: boolean;
    cancelButtonName?: string;
    confirmButtonName?: string;
    additionalActionButtonName?: string;
    cancelButtonAutomationId?: string;
    conformButtonAutomationId?: string;
    headerCloseButtonAutomationId?: string;
    title?: string;
    hideFooter?: boolean;
    onSubmit?: (event?: any) => void;
    onAdditionalButtonClick?: () => void;
    onHide: () => void;
    onCancelButtonClick?: () => void;
    size?: "sm" | "lg" | "xl";
    className?: string;
    additionalFooterElement?: ReactNode;
    backdrop?: boolean;
    closeButtonClassName?: string;
    isConfirmModal?: boolean;
    isConfirmButtonDisabled?: boolean;
    id?:string;
}

export const CustomModal: React.FC<ICustomModalProps> = props => {
    const defaultCloseButtonClass = "white";
    const closeButtonClass = props.closeButtonClassName || defaultCloseButtonClass;
    const onHideModal = () => {
        if (props.onCancelButtonClick) {
            props.onCancelButtonClick();
            return;
        } else {
            props.onHide();
        }
    };
    return (
        <Modal
            className={`custom-modal-container ${props.isConfirmModal ? "confirm-modal" : ""} ${props.className}`}
            show={props.show}
            onHide={props.onHide}
            onClick={(e: any) => {
                e.stopPropagation();
            }}
            id={props.id}
            backdrop={props.backdrop !== undefined ? props.backdrop : true}
        >
            <ModalHeader
                closeButton
                closeVariant={props.isConfirmModal ? undefined : closeButtonClass}
            >
                <Modal.Title >
                    {props.title}
                </Modal.Title>
            </ModalHeader>
            <Modal.Body className={props?.hideFooter ? "body-bottom-radius" : ""}>{props.children}</Modal.Body>
            {!props.hideFooter && (
                <Modal.Footer className={props.additionalFooterElement ? "d-flex justify-content-between" : ""}>
                    {props.additionalFooterElement && (
                        <div className="additional-action">{props.additionalFooterElement}</div>
                    )}
                    <div className="modal-button-container">
                        {props?.cancelButtonName && props?.cancelButtonName?.length > 0 && <button
                            type="button"
                            className="button-grey"
                            data-test-auto={props?.cancelButtonAutomationId}
                            onClick={() => {
                                onHideModal();
                            }}
                        >
                            {props.cancelButtonName}
                        </button>}
                        {
                            props?.confirmButtonName && props?.confirmButtonName?.length > 0 && <button
                                type="button"
                                className="button-primary-blue-2"
                                data-test-auto={props?.conformButtonAutomationId}
                                onClick={props.onSubmit}
                                disabled={props.isConfirmButtonDisabled}
                            >
                                {props.confirmButtonName}
                            </button>
                        }
                        {props.additionalActionButtonName && (
                            <button
                                type="button"
                                onClick={props.onAdditionalButtonClick}
                                className="button-primary-blue-2"
                            >
                                {props.additionalActionButtonName}
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export const ToasterMessages = {
    SUCCESS: {
        DELETE_FILE: "File deleted successfully.",
        DELETE_FILES: "File(s) deleted successfully.",
        FULLY_UPLOAD_DONE: "Request submitted successfully.",
        PARTIAL_UPLOAD_DONE: "Document(s) submitted successfully.",
        RESEND_ACCESS_CODE: "A one-time access code has been resent to your email address successfully.",
        SEND_MESSAGE: "Message sent successfully.",
    },
    ERROR: {
        DOWNLOAD_FILE: "Unable to download file(s), please try again.",
        EXPIRE_OTP:"Your One-Time Access Code has expired.",
        FILE_EXCEEDS_SIZE_LIMIT: "${fileName} cannot be uploaded since the size is greater than 3 GB.", // fileName is placeholder will be replaced by actual file name
        FILE_SIZE_EXCEEDED:"Sorry! The file(s) you are attaching exceed the allowable size limit (3GB). Please remove a few files and try again.",
        OTP_GENERATE_FAILED: "Unable to generate OTP, please try again.",
        RETRIEVE_FAILED: "Unable to fetch details, please refresh and try again.",
        SEND_MESSAGE: "There was an error while sending the message. Please try again.",
        SERVER_ERROR: "Internal Server Error, Please try to refresh.",
        UPLOAD_FILE : "Error in file upload status update.",
    },
    WARNING: {
        EMPTY_MESSAGE: "Message can't be empty.",
        EMPTY_RECIPIENTS: "Please select at least one recipient.",
        EMPTY_SENDER: "Please enter all sender details.",
        EMPTY_SUBJECT: "Subject can't be empty.",
        INVALID_EMAIL: "Email is invalid.",
        NO_USER_FOUND: "No users found.",
        RESTRICTED_FILE_TYPE: "One or more files couldn't be uploaded due to these restricted file type(s): ",
        UPLOAD_ATTACHMENT_PROGRESS: "Upload in progress. Please wait.",
        UPLOAD_IN_PROCESS: "Upload in progress. Please wait.",
    },
};
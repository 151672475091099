import axios, { AxiosError, AxiosResponse } from "axios";
import { IFormDataState } from "../../components/ShareFileCard";
import {
    IDropOffRecipient,
    IDropOffPageInfo,
    IDropOffSettings,
} from "../../core/viewModels/DropffModel";
import { DisplayError, DropoffErrorConstants } from "../../helper/Constants";
import { AppThunkAction } from "../index";
import { actionTypes } from "../types/dropOffTypes";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import { actionTypes as errorTypes } from "../types/errorTypes";
import { ErrorAction } from "./errorActions";
import { StatusType, NotificationAction } from "./notificationActions";
import { TelemetryLogger } from "../../common/Logger/AppInsights";
import { DropOffType } from "../../core/common/enums";
import { ToasterMessages } from "../../helper/ToasterMessages";
import { addPeriodAtEnd } from "../../helper/HelperFunctions";
import {actionTypes as userActions} from "../types/userTypes";
import { SetUserEmailAction } from "./userActions";

const logger = TelemetryLogger.getInstance();

interface RequestDropOffSettings {
    type: actionTypes.REQUEST_DROPOFF_SETTINGS;
}

interface ReceivedDropOffSettings {
    type: actionTypes.RECEIVED_DROPOFF_SETTINGS;
    payload: IDropOffSettings;
}

interface CompletedDropOffSettings {
    type: actionTypes.COMPLETED_DROPOFF_SETTINGS;
}

export interface RequestSendDropOffMessage {
    type: actionTypes.REQUEST_SEND_DROPOFF_MESSAGE;
}

export interface ReceivedSendDropOffMessage {
    type: actionTypes.RECEIVED_SEND_DROPOFF_MESSAGE;
}

export interface CompletedSendDropOffMessage {
    type: actionTypes.COMPLETED_SEND_DROPOFF_MESSAGE;
}

export interface RequestRemoveAttachment {
    type: actionTypes.REQUEST_REMOVE_ATTACHMENT;
}

export interface CompletedRemoveAttachment {
    type: actionTypes.COMPLETED_REMOVE_ATTACHMENT;
}

interface RequestDropOffPageInfo {
    type: actionTypes.REQUEST_DROP_OFF_PAGE_INFO;
}

interface ReceivedDropOffPageInfo {
    type: actionTypes.RECEIVED_DROP_OFF_PAGE_INFO;
    payload: IDropOffPageInfo;
}

interface RequestDropOffLink {
    type: actionTypes.REQUEST_DROP_OFF_LINK;
}

interface ReceivedDropOffLink {
    type: actionTypes.RECEIVED_DROP_OFF_LINK;
}

interface RequestDropOffRecipient {
    type: actionTypes.REQUEST_DROPOFF_RECIPIENTS;
}

interface ReceivedDropOffRecipient {
    type: actionTypes.RECEIVED_DROPOFF_RECIPIENTS;
    payload: IDropOffRecipient;
}

interface CompletedDropOffRecipient {
    type: actionTypes.COMPLETED_DROPOFF_RECIPIENTS;
}

export type DispatchActions =
    | RequestDropOffSettings
    | ReceivedDropOffSettings
    | CompletedDropOffSettings
    | RequestSendDropOffMessage
    | ReceivedSendDropOffMessage
    | CompletedSendDropOffMessage
    | RequestRemoveAttachment
    | CompletedRemoveAttachment
    | RequestDropOffPageInfo
    | ReceivedDropOffPageInfo
    | RequestDropOffLink
    | ReceivedDropOffLink
    | RequestDropOffRecipient
    | ReceivedDropOffRecipient
    | CompletedDropOffRecipient;

type KnownActions = DispatchActions | ErrorAction | NotificationAction| SetUserEmailAction;

export const validateDropOffLink =
    (clientId: string,
        dropOffType: DropOffType,
        handleError: () => void): AppThunkAction<KnownActions> =>
        (dispatch, getState) => {
            dispatch({ type: actionTypes.REQUEST_DROP_OFF_LINK });
            return axios
                .get<any>(`/api/${dropOffType}/Validate${dropOffType}Link/${clientId}`)
                .then(function (response: AxiosResponse<boolean>) {
                    let result = response.data;
                    if (result) {
                        dispatch({ type: actionTypes.RECEIVED_DROP_OFF_LINK });
                    } else {
                        dispatch({ type: actionTypes.RECEIVED_DROP_OFF_LINK });
                        handleError();
                    }
                })
                .catch((e: any) => {
                    if (e?.response?.data !== DropoffErrorConstants.FOLDER_DELETED) {

                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: ToasterMessages.ERROR.SERVER_ERROR,
                            statusType: StatusType.Error,
                        });
                    }
                    logger.trackError(
                        `validate${dropOffType}Link failed with error ${e.message} for clientId: ${clientId}`
                    );
                    handleError();
                });
        };

export const fetchDropOffPageInfo =
    (clientId: string,
        dropOffType: DropOffType,
        callback?: any): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: actionTypes.REQUEST_DROP_OFF_PAGE_INFO,
            });
            axios
                .get(`/api/${dropOffType}/GetDropOffPageInfo/` + clientId)
                .then((response: AxiosResponse<IDropOffPageInfo>) => {
                    const data = response.data;
                    dispatch({
                        type: actionTypes.RECEIVED_DROP_OFF_PAGE_INFO,
                        payload: data,
                    });
                    callback(data);
                })
                .catch(function (error: any) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: addPeriodAtEnd(error.message),
                        statusType: StatusType.Error,
                    });
                    logger.trackError(
                        `fetchDropOffPageInfo failed with error ${error.message} for clientId: ${clientId}`
                    );
                });
        };

export const fetchDropOffSettings =
    (clientId: string,
        dropOffType: DropOffType, callback?: any): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: actionTypes.REQUEST_DROPOFF_SETTINGS,
            });
            axios
                .get(`/api/${dropOffType}/GetDropOffSettings/` + clientId)
                .then((response: AxiosResponse<IDropOffSettings>) => {
                    const data = response.data;
                    dispatch({
                        type: actionTypes.RECEIVED_DROPOFF_SETTINGS,
                        payload: data,
                    });
                    callback(data);
                })
                .catch(function (error: any) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: addPeriodAtEnd(error.message),
                        statusType: StatusType.Error,
                    });
                    dispatch({
                        type: actionTypes.COMPLETED_DROPOFF_SETTINGS,
                    });
                    logger.trackError(
                        `fetch${dropOffType}Settings failed with error ${error.message} for clientId: ${clientId}`
                    );
                });
        };

export const fetchFolderDropOffRecipient =
    (clientId: string, callback?: any): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: actionTypes.REQUEST_DROPOFF_RECIPIENTS,
            });
            axios
                .get("/api/FolderDropOff/GetFolderDropOffRecipient/" + clientId)
                .then((response: AxiosResponse<IDropOffRecipient>) => {
                    const data = response.data;
                    dispatch({
                        type: actionTypes.RECEIVED_DROPOFF_RECIPIENTS,
                        payload: data,
                    });
                    callback(data);
                })
                .catch(function (error: any) {

                    if (error?.response?.status !== 404) {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: addPeriodAtEnd(error.message),
                            statusType: StatusType.Error,
                        });
                    }
                    dispatch({
                        type: actionTypes.COMPLETED_DROPOFF_RECIPIENTS,
                    });
                    logger.trackError(
                        `fetchFolderDropOffSettings failed with error ${error.message} for clientId: ${clientId}`
                    );
                });
        };

export const fetchUploadAttachmentSasLink =
    (
        documentGuid: string,
        clientId: string,
        dropOffType: DropOffType,
        callback?: any
    ): AppThunkAction<KnownActions> =>
        (dispatch) => {
            const apiEndpoint =
                dropOffType === DropOffType.DropOff
                    ? '/api/DropOff/GetDocumentUploadLink'
                    : '/api/FolderDropOff/GetDocumentUploadLink';
            axios
                .get(
                    `${apiEndpoint}/${clientId}?documentGuid=${documentGuid}`
                )
                .then((response) => {
                    const result = response.data;
                    const data = {
                        guid: documentGuid,
                        sas: result,
                    };
                    callback && callback(data);
                })
                .catch(function (error: any) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: addPeriodAtEnd(error.message),
                        statusType: StatusType.Error,
                    });
                    logger.trackError(
                        `fetchUploadAttachmentSasLink failed in dropoff with error ${error.message} for clientId: ${clientId} and documentGuid: ${documentGuid} and dropoff: ${dropOffType}`
                    );
                });
        };

export const removeAttachment =
    (
        documentGuid: string,
        clientId: string,
        dropOffType: DropOffType,
        callback?: any
    ): AppThunkAction<KnownActions> =>
        (dispatch) => {
            const apiEndpoint =
                dropOffType === DropOffType.DropOff
                    ? '/api/DropOff/DeleteAttachment'
                    : '/api/FolderDropOff/DeleteAttachment';
            dispatch({ type: actionTypes.REQUEST_REMOVE_ATTACHMENT });
            axios
                .delete(
                    `${apiEndpoint}/${clientId}?documentGuid=${documentGuid}`
                )
                .then((response) => {
                    dispatch({ type: actionTypes.COMPLETED_REMOVE_ATTACHMENT });
                    const result = response.data;
                    callback && callback(result);
                })
                .catch(function (error: any) {
                    dispatch({ type: actionTypes.COMPLETED_REMOVE_ATTACHMENT });
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: addPeriodAtEnd(error.message),
                        statusType: StatusType.Error,
                    });
                    logger.trackError(
                        `removeAttachment failed in dropoff with error ${error.message} for clientId: ${clientId} and documentGuid: ${documentGuid} and dropoff: ${dropOffType}`
                    );
                });
        };

export const sendDropOffMessage =
    (
        sentMailMessage: IFormDataState,
        clientId: string,
        dropOffType: DropOffType,
        isFolderDropOff: boolean,
        callback?: any,
        errorCallback?: (error: AxiosError) => void
    ): AppThunkAction<KnownActions> =>
        (dispatch) => {
            const apiEndpoint =
                dropOffType === DropOffType.DropOff
                    ? '/api/DropOff/SendMessage'
                    : '/api/FolderDropOff/SendMessage';
            dispatch({
                type: actionTypes.REQUEST_SEND_DROPOFF_MESSAGE,
            });
            axios
                .post(`${apiEndpoint}/${clientId}`, sentMailMessage)
                .then((response: any) => {
                    const data = response.data;
                    dispatch({
                        type: actionTypes.RECEIVED_SEND_DROPOFF_MESSAGE,
                    });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.COMPLETED_SEND_DROPOFF_MESSAGE,
                    });
                    errorCallback && errorCallback(error);
                    logger.trackError(
                        `sendDropOffMessage failed with error ${error.message
                        } for clientId: ${clientId} and message info: ${JSON.stringify(
                            { sentMailMessage, dropOffType, isFolderDropOff }
                        )}`
                    );
                });
        };

export const generateOTP =
    (
        dropOffType: DropOffType,
        clientId: string,
        senderDetails: IDropOffRecipient,
        callback: (result: string) => void,
        isResendAccess?: boolean
    ): AppThunkAction<any> =>
        (dispatch) => {
            dispatch({
                type: userActions.SET_USER_EMAIL,
                emailId: senderDetails.emailAddress
            });
            const apiEndpoint = `/api/${dropOffType}/GenerateOTP/${clientId}`;
            return axios
                .post<any>(apiEndpoint, senderDetails)
                .then(function (response: any) {
                    if (response.data === false) {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage:
                                ToasterMessages.ERROR.OTP_GENERATE_FAILED,
                            statusType: StatusType.Error,
                        });
                    } else {
                        if (isResendAccess) {
                            dispatch({
                                type: notificationTypes.NOTIFICATION,
                                statusMessage: ToasterMessages.SUCCESS.RESEND_ACCESS_CODE,
                                statusType: StatusType.Success,
                            });
                        }
                        callback(response.data);
                    }
                })
                .catch(function (error: any) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: ToasterMessages.ERROR.SERVER_ERROR,
                        statusType: StatusType.Error,
                    });
                    logger.trackError(
                        `generateOTP failed in ${dropOffType} with error ${error.message
                        } for clientId: ${clientId} and senderDetails: ${JSON.stringify(
                            { senderDetails, dropOffType }
                        )}`
                    );
                });
        };

export const verifyOTP =
    (
        userInputOtp: string,
        otpGuid: string,
        dropOffType: DropOffType,
        clientId: string,
        handleVerify?: () => void,
        handleError?: (result: any) => void
    ): AppThunkAction<KnownActions> =>
        (dispatch) => {
            const apiEndpoint =
                dropOffType === DropOffType.DropOff
                    ? `/api/DropOff/ValidateOTP/${clientId}`
                    : `/api/FolderDropOff/ValidateOTP/${clientId}`;
            return axios
                .post<any>(apiEndpoint, {
                    OTP: userInputOtp,
                    OTPGuid: otpGuid,
                })
                .then(function (response: any) {
                    let result = response.data;
                    if (result.isSuccess) {
                        handleVerify && handleVerify();
                    } else {
                        let errorDesc = result.errorDescription;
                        let errorCode = result.errorCode;

                        if (errorCode && DisplayError.includes(errorCode)) {
                            dispatch({
                                type: errorTypes.SET_ERROR_MSG,
                                data: errorDesc,
                            });

                            handleError && handleError(result);
                        } else {
                            dispatch({
                                type: notificationTypes.NOTIFICATION,
                                statusMessage: errorDesc,
                                statusType: StatusType.Error,
                            });
                        }
                    }
                })
                .catch(function (error: any) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: ToasterMessages.ERROR.SERVER_ERROR,
                        statusType: StatusType.Error,
                    });
                    logger.trackError(
                        `verifyOTP failed in dropoff with error ${error.message} for clientId: ${clientId},
           OTPGuid: ${otpGuid} and userInputOtp: ${userInputOtp} and dropoff: ${dropOffType}`
                    );
                });
        };


export const ValidateDropOffRecipient =
    (
        clientId: string,
        dropOffType: DropOffType,
        emailAddress: string,
        callback?: any
    ): AppThunkAction<KnownActions> =>
        (dispatch) => {
            axios
                .get(
                    `/api/${dropOffType}/GetDropOffRecipientValidResponse/${clientId}?emailAddress=${emailAddress}`
                )
                .then((response: AxiosResponse<boolean>) => {
                    const data = response.data;
                    callback && callback(data, emailAddress);
                })
                .catch(function (error: any) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: addPeriodAtEnd(error.message),
                        statusType: StatusType.Error,
                    });
                    logger.trackError(
                        `validating recipient failed with error ${error.message} for clientId: ${clientId}`
                    );
                });
        };
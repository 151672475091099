import { initialUserModel, IUserModel } from "./UserViewModel";

export interface IHeaderInfo {
    companyName: string;
    companyId: number;
    contactPerson: IUserModel;
}

export const initialHeaderInfo: IHeaderInfo= {
    companyName: "",
    companyId: 0,
    contactPerson: initialUserModel
}

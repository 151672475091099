import axios, { AxiosResponse } from "axios";
import { DisplayError, LinkType } from "../../helper/Constants";
import { AppThunkAction } from "../index";
import { actionTypes as errorTypes } from "../types/errorTypes";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import { StatusType, NotificationAction } from "./notificationActions";
import { actionTypes } from '../types/qaTypes';
import { ErrorAction } from "./errorActions";
import { TelemetryLogger } from "../../common/Logger/AppInsights";
import { isRequestLink } from "../../helper/HelperFunctions";
import { ToasterMessages } from "../../helper/ToasterMessages";

export interface RequestOtpPageAction {
    type: actionTypes.REQUEST_QA_PAGE;
}

export interface ReceivedOtpPageAction {
    type: actionTypes.RECEIVED_QA_PAGE;
}

export type DispatchActions = RequestOtpPageAction | ReceivedOtpPageAction;

type KnownActions = DispatchActions | ErrorAction | NotificationAction;

const logger = TelemetryLogger.getInstance();

export const validatePage = (clientId: string, handleSuccess: (data: any) => void, handleError: (result: any) => void, type?: LinkType):
    AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_QA_PAGE });
        const APILink = isRequestLink(type) ? "DocumentRequestQA/ValidateRecipient" : "QA/ValidateRecipient";
        return axios.get<any>(`/api/${APILink}/` + clientId)
            .then(function (response: AxiosResponse<any>) {

                let result = response.data;
                if (result.isSuccess) {
                    dispatch({
                        type: actionTypes.RECEIVED_QA_PAGE
                    });
                    handleSuccess(result.data);
                }
                else {
                    let errorDesc = result.errorDescription;
                    let errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {

                        dispatch({
                            type: errorTypes.SET_ERROR_MSG,
                            data: errorDesc
                        });
                        handleError(result);

                    }
                    else {

                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        });

                        dispatch({ type: actionTypes.RECEIVED_QA_PAGE });

                    }
                }
            })
            .catch((e: any) => {
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: ToasterMessages.ERROR.SERVER_ERROR,
                    statusType: StatusType.Error
                })
                logger.trackError(
                    `validatePage for QA failed with error ${e.message} for clientId: ${clientId}`
                );
            }
            );
    }


export const verifySecurityAnswer = (securityAnswer: string, clientId: string,
    handleVerify?: (guid: string) => void, handleError?: (result: any) => void, type?: LinkType):
    AppThunkAction<KnownActions> => (dispatch, getState) => {

        const APILink = type === LinkType.DocumentRequestLink ? "DocumentRequestQA/ValidateQA" : "QA/ValidateQA";

        return axios.post<any>(`api/${APILink}/${clientId}`, { userInput: securityAnswer })
            .then(function (response: any) {
                let result = response.data;
                if (result.isSuccess) {
                    localStorage.setItem("clientId", result.data.messageGuid);
                    localStorage.setItem("userId", result.data.userId);
                    localStorage.setItem("access", result.data.accessToken);
                    handleVerify && handleVerify(result.data);
                }
                else {
                    let errorDesc = result.errorDescription;
                    let errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: errorTypes.SET_ERROR_MSG,
                            data: errorDesc
                        });

                        handleError && handleError(result);
                    }
                    else {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        })
                    }
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: ToasterMessages.ERROR.SERVER_ERROR,
                    statusType: StatusType.Error
                });
                logger.trackError(
                    `verifySecurityAnswer failed with error ${error.message} for clientId: ${clientId}`
                );
            });
    }
import axios, { AxiosResponse } from "axios";

import { DisplayError, LinkType } from "../../helper/Constants";
import { AppThunkAction } from "../index";
import { actionTypes as errorTypes } from "../types/errorTypes";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import { StatusType, NotificationAction } from "./notificationActions";
import { actionTypes } from '../types/otpTypes';
import { ErrorAction } from "./errorActions";
import { TelemetryLogger } from "../../common/Logger/AppInsights";
import { isRequestLink } from "../../helper/HelperFunctions";
import { ToasterMessages } from "../../helper/ToasterMessages";

export interface RequestOtpPageAction {
    type: actionTypes.REQUEST_OTP_PAGE;
}

export interface ReceivedOtpPageAction {
    type: actionTypes.RECEIVED_OTP_PAGE;
}

export type DispatchActions = RequestOtpPageAction | ReceivedOtpPageAction;

type KnownActions = DispatchActions | ErrorAction | NotificationAction;

const logger = TelemetryLogger.getInstance();

//To check if Link Lock is locked
export const validatePage = (clientId: string, handleSuccess: () => void, handleError: (result: any) => void, type?: LinkType):
    AppThunkAction<KnownActions> => (dispatch, getState) => {
        const APILink = isRequestLink(type) ? "DocumentRequestOTP/ValidateRecipient" : "OTP/ValidateRecipient"
        dispatch({ type: actionTypes.REQUEST_OTP_PAGE });
        return axios.get<any>(`/api/${APILink}/${clientId}`)
            .then(function (response: AxiosResponse<any>) {

                let result = response.data;
                if (result.isSuccess) {
                    dispatch({ type: actionTypes.RECEIVED_OTP_PAGE });
                    handleSuccess();
                }
                else {
                    let errorDesc = result.errorDescription;
                    let errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {

                        dispatch({
                            type: errorTypes.SET_ERROR_MSG,
                            data: errorDesc
                        });

                        handleError(result);

                    }
                    else {

                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        });

                        dispatch({ type: actionTypes.RECEIVED_OTP_PAGE });

                    }

                }
            })
            .catch((e: any) => {
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: ToasterMessages.ERROR.SERVER_ERROR,
                    statusType: StatusType.Error
                });
                logger.trackError(
                    `validatePage failed for OTP with error ${e.message} for clientId: ${clientId}`
                  );
            }
            );
    }


export const verifyOTP = (OTP: string, clientId: string,
    handleVerify?: (result: any) => void, handleError?: (result: any) => void, type?: LinkType):
    AppThunkAction<KnownActions> => (dispatch, getState) => {
        const APILink = isRequestLink(type) ? "DocumentRequestOTP/ValidateOTP" : "OTP/ValidateOTP"
        return axios.post<any>(`/api/${APILink}/${clientId}`, { userInput: OTP })
            .then(function (response: any) {
                let result = response.data;
                if (result.isSuccess) {
                    localStorage.setItem("clientId", result.data.messageGuid);
                    localStorage.setItem("userId", result.data.userId);
                    localStorage.setItem("access", result.data.accessToken);
                    handleVerify && handleVerify(result);
                }
                else {
                    let errorDesc = result.errorDescription;
                    let errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: errorTypes.SET_ERROR_MSG,
                            data: errorDesc
                        });

                        handleError && handleError(result);
                    }
                    else {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        })
                    }
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: ToasterMessages.ERROR.SERVER_ERROR,
                    statusType: StatusType.Error
                })
                logger.trackError(
                    `verifyOTP failed with error ${error.message} for clientId: ${clientId}`
                  );
            });
    }

export const generateOTP = (clientId: string, type?: LinkType ,isResendAccess?: boolean): AppThunkAction<any> => (dispatch, getState) => {
    const APILink = isRequestLink(type) ? "DocumentRequestOTP/GenerateOTP" : "OTP/GenerateOTP";
    return axios.post<any>(`/api/${APILink}/${clientId}`, null)
        .then(function (response: any) {
            if (response.data === false) {
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: ToasterMessages.ERROR.OTP_GENERATE_FAILED,
                    statusType: StatusType.Error
                })
            }
            else {
                if(isResendAccess){
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: ToasterMessages.SUCCESS.RESEND_ACCESS_CODE,
                        statusType: StatusType.Success
                    })
                } 
            }
        })
        .catch(function (error: any) {
            dispatch({
                type: notificationTypes.NOTIFICATION,
                statusMessage: ToasterMessages.ERROR.SERVER_ERROR,
                statusType: StatusType.Error
            });
            logger.trackError(
                `generateOTP failed with error ${error.message} for clientId: ${clientId}`
              );
        });
}
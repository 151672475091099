import { Action, Reducer } from "redux";
import { DispatchActions } from "../actions/oneHubActions";
import { actionTypes } from "../types/oneHubTypes";
import produce from "immer";
import { IOneHubUserModel } from "../../core/viewModels/oneHub/MergeEmails";

export interface IOneHubState {
    isFetchingSenderInfo: boolean,
    senderInfo: IOneHubUserModel[]
}

const initialOneHubState: IOneHubState = {
    isFetchingSenderInfo: false,
    senderInfo: []
};

export const reducer: Reducer<IOneHubState> = produce(
    (draftState: IOneHubState = initialOneHubState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case actionTypes.REQUEST_DROPOFF_SENDER_DETAILS: draftState.isFetchingSenderInfo = true;
                break;
            case actionTypes.RECEIVED_DROPOFF_SENDER_DETAILS:
                let senderInfo = action.payload;
                draftState.senderInfo = senderInfo;
                draftState.isFetchingSenderInfo = false;
                break;
            case actionTypes.COMPLETED_DROPOFF_SENDER_DETAILS:
                draftState.isFetchingSenderInfo = false;
                break;
        }
        return draftState;
    }
);